<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <b-dropdown-item v-on="on" @click.prevent="refresh">
        {{ $t("collections.dialog_model.action.new_record") }}
      </b-dropdown-item>
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">{{
            $t("collections.dialog_model.title")
          }}</span>
          <small>
            {{ $t("collections.dialog_model.subtitle") }}
          </small>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="errors.length > 0">
              <v-col cols="12">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="collection.name"
                  required
                  :label="$t('collections.dialog_model.fields.name.label')"
                  ref="f_name"
                  :rules="[
                    v =>
                      !!v ||
                      this.$t('collections.dialog_model.fields.name.rule')
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="collection.id == 0">
              <v-col cols="12">
                <v-select
                  v-model="predefined"
                  :label="
                    $t('collections.dialog_model.fields.dependent_by.label')
                  "
                  ref="f_collection_predefined"
                  item-value="id"
                  item-text="name"
                  item-key="id"
                  return-object
                  :items="collections_predefined"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color=" darken-1" text @click="showDialog = false"
            >{{ $t("common.close") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >{{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import { required } from "vuelidate/lib/validators";
import {
  PULL_COLLECTIONS,
  CLOSE_DIALOG,
  POST_COLLECTION,
  PULL_COLLECTIONS_PREDEFINED
} from "@/core/services/store/collections.module";

export default {
  props: {
    itemId: {
      type: Number
    }
  },
  data: () => ({
    valid: true,
    dialog: false,
    predefined: {
      id: "6",
      name: "Custom",
      description: null,
      schema: null
    },
    term: ""
  }),
  methods: {
    refresh: function() {
      this.term = "";
    },
    close() {
      this.dialog = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch(POST_COLLECTION, {
            name: this.$refs.f_name.value,
            type_id: this.$refs.f_collection_predefined.value.id
          })
          .then(() => {
            this.dialog = false;
            this.$store.dispatch(CLOSE_DIALOG);
            this.$store.dispatch(PULL_COLLECTIONS);
          });
      }
    }
  },
  validations: {
    form: {
      name: { required }
    }
  },
  computed: {
    showDialog: {
      get: function() {
        return this.dialog || this.show_dialog || this.needCollection;
      },
      set: function(value) {
        this.dialog = value;
        this.$store.dispatch(CLOSE_DIALOG);
      }
    },
    ...mapState({
      errors: state => state.collections.errors,
      message: state => state.collections.message,
      hasMessage: state => state.collections.hasMessage,
      collection: state => state.collections.collection,
      collections_predefined: state => state.collections.collections_predefined,
      show_dialog: state => state.collections.showDialog,
      needCollection: state => state.collections.needCollection
    })
  },
  beforeCreate() {
    this.$store.dispatch(PULL_COLLECTIONS_PREDEFINED);
  }
};
</script>
