var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('b-dropdown-item',_vm._g({on:{"click":function($event){$event.preventDefault();return _vm.refresh($event)}}},on),[_vm._v(" "+_vm._s(_vm.$t("collections.dialog_model.action.new_record"))+" ")])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("collections.dialog_model.title")))]),_c('small',[_vm._v(" "+_vm._s(_vm.$t("collections.dialog_model.subtitle"))+" ")])]),_c('v-card-text',[_c('v-container',[(_vm.errors.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"alert fade alert-danger",class:{ show: _vm.errors.length },attrs:{"role":"alert"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i,staticClass:"alert-text"},[_vm._v(" "+_vm._s(error)+" ")])}),0)])],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"f_name",attrs:{"required":"","label":_vm.$t('collections.dialog_model.fields.name.label'),"rules":[
                  function (v) { return !!v ||
                    this$1.$t('collections.dialog_model.fields.name.rule'); }
                ]},model:{value:(_vm.collection.name),callback:function ($$v) {_vm.$set(_vm.collection, "name", $$v)},expression:"collection.name"}})],1)],1),(_vm.collection.id == 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{ref:"f_collection_predefined",attrs:{"label":_vm.$t('collections.dialog_model.fields.dependent_by.label'),"item-value":"id","item-text":"name","item-key":"id","return-object":"","items":_vm.collections_predefined,"required":""},model:{value:(_vm.predefined),callback:function ($$v) {_vm.predefined=$$v},expression:"predefined"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":" darken-1","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(_vm._s(_vm.$t("common.close"))+" ")]),_c('v-spacer'),_c('v-btn',{ref:"submit",attrs:{"color":"primary dark large"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("common.save"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }