<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-form ref="form" lazy-validation>
        <v-card-title>
          <h3>{{ $t("boost_manager.modal.add_boost.title") }}</h3>
          <small>{{ $t("boost_manager.modal.add_boost.subtitle") }}</small>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            :loading="loading"
            v-model="selectedItem"
            :label="
              $t('boost_manager.modal.add_boost.fields.select_item.label')
            "
            :items="items"
            required
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="close">{{
            $t("common.back")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >{{ $t("boost_manager.modal.add_boost.actions.select_object") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import { PULL_SCHEMA_AUTOCOMPLETE } from "@/core/services/store/schema_autocomplete.module";

export default {
  data() {
    return {
      selectedItem: null,
      dialog: false
    };
  },
  props: {
    schema: {
      type: Object,
      required: true
    },
    callback: {
      type: Function,
      required: true
    }
  },
  methods: {
    save() {
      this.callback(this.schema, this.selectedItem);
      this.schema = null;
      this.dialog = false;
    },
    close() {
      this.callback(this.schema, "");
      this.schema = null;
      this.dialog = false;
    },
    reloadData() {
      this.items = [];
      this.selectedItem = null;
      this.$store.dispatch(PULL_SCHEMA_AUTOCOMPLETE, this.schema);
    }
  },
  watch: {
    changeSchema() {
      if (this.schema && this.schema.field_name) {
        this.reloadData();
        this.dialog = true;
      }
    }
  },
  computed: {
    changeSchema() {
      return this.schema;
    },
    ...mapState({
      items: state => state.schema_autocomplete.items,
      loading: state => state.schema_autocomplete.loading,
      message: state => state.message.message
    })
  }
};
</script>
